import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { baseActions } from "redux/actions";
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";

import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import api from "services/api";
import http from "services/http";
import JobDataTable from "./ApplicantDataTable";
import { KeyboardArrowDown, Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

const statusFilter = [
  {
    label: "Semua",
    value: "",
  },
  {
    label: "Disubmit",
    value: "SUBMITTED",
  },
  {
    label: "Dalam Proses",
    value: "IN_PROGRESS",
  },
  {
    label: "Diterima",
    value: "ACCEPTED",
  },
  {
    label: "Ditolak",
    value: "REJECTED",
  },
];

const statusOptions = [
  {
    label: "Disubmit",
    value: "SUBMITTED",
  },
  {
    label: "Dalam Proses",
    value: "IN_PROGRESS",
  },
  {
    label: "Diterima",
    value: "ACCEPTED",
  },
  {
    label: "Ditolak",
    value: "REJECTED",
  },
];

export default function ListApplicant() {
  const { job_vacancy_id } = useParams();
  const dispatch = useDispatch();
  const { register, watch, setValue, handleSubmit } = useForm();
  // const history = useHistory();

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [jobData, setJobData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });
  const [showModal, setShowModal] = useState(false);

  /**
   * Fetch Data
   */

  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { email, status } = watch("filter");

      const { data } = await http.get(
        `${api.v1.admin.job_vacancy.main}/${job_vacancy_id}/applications`,
        {
          params: { limit, page, email, status },
        }
      );

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setJobData(records);

      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    try {
      const { data } = await http.get(
        `${api.v1.admin.job_vacancy.main}/${job_vacancy_id}/applications/${id}`
      );

      setValue("applicants", data.data);
      setShowModal(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { applicants } = payload;

      await http.put(
        `${api.v1.admin.job_vacancy.main}/${job_vacancy_id}/applications/${applicants.id}`,
        applicants
      );

      setShowModal(false);

      getList();

      Swal.fire("Sukses", "Data Pelamar Berhasil Diperbaharui", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const exportData = async () => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = "Data Pelamar.xlsx";

      const res = await http.get(
        `${api.v1.admin.job_vacancy.main}/${job_vacancy_id}/applications/export`,
        {
          responseType: "blob",
        }
      );

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setValue("applicant.note", null);
    setValue("applicant.status", "NOT_YET");
    setValue("applicant.id", null);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Pelamar</title>
      </Helmet>

      <DefaultHeader
        section="Data Pelamar"
        subsection="Data Lowongan Pekerjaan"
        routeBack="/job"
      />

      <Container maxWidth={false} component={Box} id="jobs">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={exportData}
                  >
                    Export Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <Grid className="p-3 d-flex justify-content-between">
                      <Grid sm={8} xs={12} className="d-flex">
                        {/* status */}
                        <Grid sm={4} className="mr-2">
                          <Autocomplete
                            id="combo-box-category"
                            options={statusFilter}
                            getOptionLabel={option => option.label}
                            closeIcon={null}
                            onInputChange={(e, val) => {
                              const { value } = statusFilter.filter(
                                data => data.label === val
                              )[0];

                              setValue("filter.status", value);
                              getList();
                            }}
                            value={
                              statusFilter.filter(
                                data => data.value === watch("filter.status")
                              )[0]
                            }
                            popupIcon={
                              <Box
                                component={KeyboardArrowDown}
                                width="1.25rem!important"
                                height="1.25rem!important"
                              />
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Status"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        sm={4}
                        xs={12}
                        className="d-flex justify-content-end"
                      >
                        <OutlinedInput
                          type="text"
                          placeholder="Cari berdasarkan Email"
                          startAdornment={
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          }
                          className="mr-3"
                          onKeyPress={e => e.key === "Enter" && getList()}
                          {...register("filter.email")}
                          style={{ width: "220px" }}
                        />

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => getList()}
                        >
                          <Box
                            component={Search}
                            width="1.25rem!important"
                            height="1.25rem!important"
                            position="relative"
                          />
                        </Button>
                      </Grid>
                    </Grid>

                    <JobDataTable
                      loading={loadingData}
                      data={jobData}
                      getDetailData={getDetailData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                    />
                  </Card>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>

      {/* Modal  */}
      <Dialog
        open={showModal}
        fullWidth={true}
        maxWidth="md"
        onClose={handleCloseModal}
      >
        <Container className="py-4">
          <Box textAlign="Center">
            <h2>Data Pelamar</h2>
          </Box>

          <form onSubmit={handleSubmit(putUpdateData)}>
            <Grid container className="py-4 ">
              <Grid item xs={12}>
                <FormGroup>
                  <FormControl variant="outlined" fullWidth>
                    <FormLabel id="option-target-platform-label">
                      Status
                    </FormLabel>
                    <Select
                      IconComponent={KeyboardArrowDown}
                      labelId="option-target-platform-label"
                      value={
                        watch("applicants.status") || statusOptions[0].value
                      }
                      {...register("applicants.status")}
                    >
                      {statusOptions.map((val, key) => (
                        <MenuItem key={key} value={val.value}>
                          {val.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormGroup>
              </Grid>
              <Grid item sm={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  Simpan
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Dialog>
    </>
  );
}
