import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { baseActions } from "redux/actions";
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
} from "@material-ui/core";

import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import api from "services/api";
import http from "services/http";
import Swal from "sweetalert2";
import JobDataTable from "./JobDataTable";

export default function ListJob() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { register, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      job: {
        job_vacancies_excel: null,
        job_vacancy_ids: [],
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [jobData, setJobData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [showModalJob, setShowModalJob] = useState(false);

  /**
   * Fetch Data
   */

  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(`${api.v1.admin.job_vacancy.main}`, {
        params: { limit, page },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setJobData(records);

      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const postCreateData = async val => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { job } = val;

      const formData = new FormData();

      formData.append("job_vacancies_excel", job.job_vacancies_excel[0]);

      await http.post(`${api.v1.admin.job_vacancy.main}/import`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        responseType: "blob",
      });

      Swal.fire("Sukses", "Berhasil Import Lowongan Kerja", "success");

      setValue("job.job_vacancies_excel", null);
      setShowModalJob(false);
      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;
        const message =
          status === 422 && response?.data?.errors
            ? response?.data?.errors[0]
            : response?.data?.meta?.message;

        Swal.fire("Gagal", message, "error");
      }

      Swal.fire("Gagal", "Terjadi Kesalahan", "error");
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async () => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const dataDelete = { job_vacancy_ids: watch("job.job_vacancy_ids") };

      await http.delete(api.v1.admin.job_vacancy.main, { data: dataDelete });

      Swal.fire("Sukses", "Berhasil Hapus Lowongan Kerja", "success");

      getList();
      setValue("job.job_vacancy_ids", []);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const getTemplateExcel = async () => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noreferrer noopener";
      link.download = "Template Import Job.xlsx";

      const res = await http.get(
        `${api.v1.admin.job_vacancy.main}/template-import`,
        {
          responseType: "blob",
        }
      );

      link.href = URL.createObjectURL(
        new Blob([res.data], { type: "text/xlsx" })
      );
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleOpenModal = () => {
    setShowModalJob(true);
  };

  const handleCloseModal = () => {
    setShowModalJob(false);
  };

  const handleMarkDelete = id => {
    const currentIds = watch("job.job_vacancy_ids");

    if (currentIds.includes(id)) {
      setValue(
        "job.job_vacancy_ids",
        currentIds.filter(existingId => existingId !== id)
      );
    } else {
      setValue("job.job_vacancy_ids", [...currentIds, id]);
    }
  };

  const handleDeleteData = () => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus pekerjaan",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData();
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Lowongan Pekerjaan</title>
      </Helmet>

      <DefaultHeader section="Data Lowongan Pekerjaan" />

      <Container maxWidth={false} component={Box} id="jobs">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4" xs="auto">
                  <Box
                    justifyContent="start"
                    display="flex"
                    flexWrap="wrap"
                    gridGap={8}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => history.push("/job/create")}
                    >
                      Tambah Data
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleDeleteData}
                    >
                      Hapus Data
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpenModal}
                    >
                      Import Data
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={getTemplateExcel}
                    >
                      Unduh Template Import
                    </Button>
                  </Box>
                </Grid>

                <Grid>
                  <Card>
                    <JobDataTable
                      loading={loadingData}
                      data={jobData}
                      selectedJob={watch("job.job_vacancy_ids")}
                      handleMarkDelete={handleMarkDelete}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                    />
                  </Card>
                </Grid>

                {/* Modal Create & Update Brand */}
                <Dialog
                  open={showModalJob}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="center">
                      <h2>Import Data Pekerjaan</h2>
                    </Box>
                    <form onSubmit={handleSubmit(postCreateData)}>
                      <Grid container className="py-4">
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Dokumen Excel</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="file"
                              {...register("job.job_vacancies_excel")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            Import
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
