import React, { useEffect, useState } from "react";

import {
  Box,
  Card,
  Container,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
  TextField,
  FormControl,
  Button,
  IconButton,
  makeStyles,
  SnackbarContent,
} from "@material-ui/core";
import ReactSelect from "react-select";

import { KeyboardArrowDown } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";

import theme from "assets/theme/theme";
import snackbarTheme from "assets/theme/components/snackbar.js";

import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";

import DefaultHeader from "components/Headers/DefaultHeader";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Spinner from "components/Loading/Indicator/Spinner";

const snackbarStyles = makeStyles(snackbarTheme);

export default function DetailJobPage() {
  const { job_vacancy_id } = useParams();
  const styles = { ...snackbarStyles() };
  const dispatch = useDispatch();
  const history = useHistory();

  const { register, watch, setValue } = useForm();

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [companyOption, setCompanyOption] = useState([]);
  const [skills, setSkills] = useState([""]);
  const [optionCourseData, setOptionCourseData] = useState([]);

  const arrangementOption = [
    { label: "Onsite", value: "ONSITE" },
    { label: "Hybrid", value: "HYBRID" },
    { label: "Remote", value: "REMOTE" },
  ];

  const workStatusOption = [
    { label: "Full Time", value: "FULL_TIME" },
    { label: "Part Time", value: "PART_TIME" },
    { label: "Freelance", value: "FREELANCE" },
    { label: "Internship", value: "INTERNSHIP" },
  ];

  /**
   * Fetch Data
   */
  const getDetailJob = async () => {
    try {
      setLoadingData(true);

      const { data } = await http.get(
        `${api.v1.admin.job_vacancy.main}/${job_vacancy_id}`
      );

      const dataJob = {
        title: data.data.title,
        description: data.data.description,
        min_salary: data.data.range_salary?.min,
        max_salary: data.data.range_salary?.max,
        closed_at: data.data.closed_at,
        requirements: data.data.requirements,
        others: data.data.others,
        work_status: data.data.work_status,
        work_status_option: workStatusOption.find(
          ({ value }) => value === data.data.work_status
        ),
        work_arrangement: data.data.work_arrangement,
        work_arrangement_option: arrangementOption.find(
          ({ value }) => value === data.data.work_arrangement
        ),
        recommendation_courses: data.data.recommendation_courses.map(
          ({ id }) => id
        ),

        job_vacancy_company_id: data.data.company.id,
        company: {
          name: data.data.company.name,
          id: data.data.company.id,
        },
      };

      const dataSkills = data.data.skills.map(skill => skill.skill);
      setSkills(dataSkills);

      const selected_courses = data.data.recommendation_courses.map(course => ({
        label: course.name,
        value: course.id,
        price: Number(course.price),
        discount_price: Number(course.discount_price),
      }));

      //set img

      setValue("selected_courses", selected_courses);

      setValue("job_vacancy", dataJob);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getListCompanies = async () => {
    try {
      const { data } = await http.get(api.v1.admin.job_vacancy.company, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;

      setCompanyOption(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getOptionCourses = async () => {
    try {
      const { data } = await http.get(`${api.v1.admin.course.main}`, {
        params: { option: true, price: true },
      });

      setError(null);

      const { records } = data.data;

      const options = records.map(item => ({
        label: item.name,
        value: item.id,
        price: Number(item.price),
        discount_price: Number(item.discount_price),
      }));

      setOptionCourseData(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  /**
   * Handling Events
   */
  const handleAddSkill = () => {
    setSkills([...skills, ""]);
  };

  const handleDeleteSkill = index => {
    const updatedSkill = skills.filter((_, i) => i !== index);

    setSkills(updatedSkill);
  };

  const handleChangeSkill = (e, index) => {
    const updatedSkill = [...skills];
    updatedSkill[index] = e.target.value;
    setSkills(updatedSkill);
  };

  const putCreateJobVacancy = async () => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const payload = watch("job_vacancy");

      await http.put(`${api.v1.admin.job_vacancy.main}/${job_vacancy_id}`, {
        ...payload,
        skills,
      });

      Swal.fire(
        "Sukses",
        "Data lowongan pekerjaan berhasil diperbarui",
        "success"
      );

      history.push("/job");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  useEffect(() => {
    getDetailJob();
    getListCompanies();
    getOptionCourses();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Detail Lowongan Pekerjaan</title>
      </Helmet>

      <DefaultHeader
        section="Detail Lowongan Pekerjaan"
        subsection="Data Lowongan Pekerjaan"
        routeBack="/job"
      />

      <Container maxWidth={false} component={Box} id="create-course-page">
        <Grid container>
          <Grid item xs={12}>
            {loadingData ? (
              <Box style={{ marginTop: "260px" }}>
                <Spinner />
              </Box>
            ) : (
              <Card className="p-4">
                {error && (
                  <Grid container>
                    <Grid item xs={12} className="mb-2">
                      <SnackbarContent
                        classes={{ root: styles.errorSnackbar }}
                        action={
                          <Box
                            component={IconButton}
                            padding="0!important"
                            onClick={() => setError(null)}
                          >
                            <Box
                              component="span"
                              color={theme.palette.white.main}
                            >
                              ×
                            </Box>
                          </Box>
                        }
                        message={<Box>{error}</Box>}
                      />
                    </Grid>
                  </Grid>
                )}

                {/* Master Data */}
                <Grid container>
                  <Grid item xs={12} className="mb-2">
                    <h2 className="text-muted">Master Data</h2>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormGroup>
                      <FormLabel>Judul Lowongan</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="text"
                        {...register("job_vacancy.title")}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormGroup>
                      <FormLabel>Minimum Gaji (Optional)</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="number"
                        name="name"
                        {...register("job_vacancy.min_salary")}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormGroup>
                      <FormLabel>Maskimum Gaji (Optional)</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="number"
                        name="name"
                        {...register("job_vacancy.max_salary")}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <FormGroup>
                      <FormControl variant="outlined" fullWidth>
                        <FormLabel id="option-competence-aspect-label">
                          Perushaan
                        </FormLabel>
                        <Autocomplete
                          id="combo-box-category"
                          options={companyOption}
                          getOptionLabel={option => option.name}
                          closeIcon={null}
                          value={watch("job_vacancy.company")}
                          getOptionSelected={(option, val) =>
                            option.id === val.id
                          }
                          onChange={(e, val) => {
                            setValue(
                              "job_vacancy.job_vacancy_company_id",
                              val.id
                            );
                          }}
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField {...params} variant="filled" />
                          )}
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <FormGroup>
                      <FormControl variant="outlined" fullWidth>
                        <FormLabel id="option-competence-aspect-label">
                          Tipe Pekerjaan
                        </FormLabel>
                        <Autocomplete
                          id="combo-box-category"
                          options={workStatusOption}
                          getOptionLabel={option => option.label}
                          closeIcon={null}
                          value={watch("job_vacancy.work_status_option")}
                          getOptionSelected={(option, val) =>
                            option.value === val.value
                          }
                          onChange={(e, val) => {
                            setValue("job_vacancy.work_status", val.value);
                          }}
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField {...params} variant="filled" />
                          )}
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <FormGroup>
                      <FormControl variant="outlined" fullWidth>
                        <FormLabel id="option-competence-aspect-label">
                          Kebijakan Kerja
                        </FormLabel>
                        <Autocomplete
                          id="combo-box-category"
                          options={arrangementOption}
                          getOptionLabel={option => option.label}
                          closeIcon={null}
                          value={watch("job_vacancy.work_arrangement_option")}
                          getOptionSelected={(option, val) =>
                            option.value === val.value
                          }
                          onChange={(e, val) => {
                            setValue("job_vacancy.work_arrangement", val.value);
                          }}
                          popupIcon={
                            <Box
                              component={KeyboardArrowDown}
                              width="1.25rem!important"
                              height="1.25rem!important"
                            />
                          }
                          renderInput={params => (
                            <TextField {...params} variant="filled" />
                          )}
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <FormGroup>
                      <FormLabel>Tanggal Tutup</FormLabel>
                      <OutlinedInput
                        fullWidth
                        type="datetime-local"
                        defaultValue={moment().format("yyyy-MM-DDTHH:mm:ss")}
                        {...register("job_vacancy.closed_at")}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormGroup>
                      <FormLabel>Deskripsi Pekerjaan</FormLabel>
                      <ReactQuill
                        theme="snow"
                        value={watch("job_vacancy.description")}
                        onChange={val =>
                          setValue("job_vacancy.description", val)
                        }
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormGroup>
                      <FormLabel>Persyaratan (Optional)</FormLabel>
                      <ReactQuill
                        theme="snow"
                        value={watch("job_vacancy.requirements")}
                        onChange={val =>
                          setValue("job_vacancy.requirements", val)
                        }
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <FormLabel>Others (Optional)</FormLabel>
                      <ReactQuill
                        theme="snow"
                        value={watch("job_vacancy.others")}
                        onChange={val => setValue("job_vacancy.others", val)}
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <FormLabel>Keahlian</FormLabel>
                    </FormGroup>
                  </Grid>

                  {skills.map((_, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={8} md={10}>
                        <FormGroup>
                          <OutlinedInput
                            fullWidth
                            type="text"
                            value={skills[index]}
                            onChange={e => handleChangeSkill(e, index)}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={4} md={2}>
                        <Button
                          variant="contained"
                          color={index === 0 ? "primary" : "secondary"}
                          fullWidth
                          onClick={() => {
                            if (index === 0) handleAddSkill();
                            else handleDeleteSkill(index);
                          }}
                        >
                          {index === 0 ? "Tambah" : "Hapus"}
                        </Button>
                      </Grid>
                    </React.Fragment>
                  ))}

                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControl variant="outlined" fullWidth>
                        <FormLabel id="option-status-promo-code-label">
                          Kelas Rekomendasi
                        </FormLabel>
                        <ReactSelect
                          isMulti
                          options={optionCourseData}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={val => {
                            setValue("selected_courses", val);
                            setValue(
                              "job_vacancy.recommendation_courses",
                              val.map(({ value }) => value)
                            );
                          }}
                          value={watch("selected_courses")}
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>
                </Grid>

                <Grid container className="mt-5">
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={putCreateJobVacancy}
                    >
                      Simpan
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
